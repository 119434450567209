<template>
  <div >
    <el-container>

      <el-header height="50px">
        <!--  头标签  -->
        <div id="nav_header">
          <div class="navbar_dark">
            <router-link to="/" class="theme_color">创美优格运营平台</router-link>
          </div>
          <div id="header_main">
              <div id="centent_main">
                  <ul>
                    <li v-for="item in menuData" :key="item.name">
                      <router-link :to="item.path" active-class="activeClass">{{item.lable}}</router-link>
                    </li> 
                </ul>
              </div>
               <div id="right_main">
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                       <span >18530018103</span><i class="el-icon-caret-bottom el-icon--right"></i> <span style="margin-left: 8px;">&nbsp;&nbsp;</span>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item><span style="font-size: 13px;">2022-03-12到期</span></el-dropdown-item>
                      <el-dropdown-item>使用说明</el-dropdown-item>
                      <el-dropdown-item>公告管理</el-dropdown-item>
                      <el-dropdown-item disabled>操作记录</el-dropdown-item>
                      <el-dropdown-item >设置密码</el-dropdown-item>
                      <el-dropdown-item divided>退出</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
               </div>
     
          </div>
        </div>
      </el-header>

      <el-main  class="el_main_backtop" >
        <div id="main_body">
          <div class="wrapper_body container">
            <div id="row" v-if="$route.meta.length > 0">
                    <!-- 路由 -->
                  <ul>
                    <li v-for="item in $route.meta" :key="item.name"> 
                        <router-link :to="item.path" active-class="innerActiveClass">{{item.lable}}</router-link>
                     </li>
                 </ul>
            </div>
            <div class="wrapper_inner">
              <router-view></router-view>
            </div>
          </div>
        </div>
        <el-backtop  :visibility-height="120" :bottom="280" :right="10" style="width: 50px; height: 50px;"></el-backtop>
      </el-main>
    </el-container>
  </div>
</template>
  
  <script>
export default {
  name: "myLayout",
  data() {
    return {
      menuData:[
          { path: "/index",name: "index",lable: "首页"},
          { path: "/record",name: "user",lable: "使用记录"},
          { path: "/cabinet",name: "cabinet",lable: "箱柜设置"},
          { path: "/finance",name: "finance",lable: "财务信息"},
          { path: "/dataStatistics",name: "dataStatistics",lable: "汇总统计"},
          { path: "/systemSetting",name: "systemSetting",lable: "系统设置"},
      ],

    }
  },
  created(){
  },  
};
</script>


<style lang="scss" scoped>

.wrapper_inner{
    margin-top: 1rem;
    padding: 0.5rem 2rem 4rem 3rem;
}
.wrapper_body {
  margin: 1.2rem auto;
  width: 90%;
  background-color: #fff;
  border-radius: 0.7rem;
  box-shadow: 0 9px 9px #ccc;
  overflow: hidden;
  margin-bottom: 4rem;
}


     /* 移动端设备 */
 @media (max-width: 575px) { 
 
  .container {
    max-width: 540px;
 }  
}

/* 移动端设备（横屏（低分辨率）） */
@media (min-width: 576px) and (max-width: 767px) {  
  .container {
    max-width: 540px;
 }
}

/* iPad/平板设备  and  移动端设备（横屏（高分辨率））*/
@media (min-width: 768px) and (max-width: 991px) { 
  .container {
    max-width: 720px;
 }
}

/* iPad/平板设备（横屏） and  iPad/平板设备(高分辨率) */
@media (min-width: 992px) and (max-width: 1199px) { 
  .container {
    max-width: 960px;
 }
}

 /* PC端设备 */
@media (min-width: 1200px) { 
  .container {
    max-width: 1240px;
 }
}

.activeClass{
  font-weight: 700;
}

.container {
    width: 90%;
    padding:0px 15px 15px 15px;
    margin-right: auto;
    margin-left: auto;
}
#main_body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  background-color: transparent;
  color: #333;
}

.theme_color {
  color: #eb8603;
}

#row{
  position: relative;
  display: flex;
  background-color: #f5f5f5;
  margin: 0px -15px 10px -15px;
  // height: 36px;

  ul{
    list-style:none;
    font-size: 0.9rem;
    margin: 0 auto;
    line-height: 46px;
    a{
      padding: 0.5rem 0.75rem;
      color: #007bff;
      font-weight: 400;
      font-size: 87%;
    }
    a:hover{ text-decoration: underline; } 
    .innerActiveClass{
      font-weight: 700;
      color: #eb8603;
    }
    li{
      display: inline-block;
    }
  }
}


#header_main{

  position: relative;
  display: flex;
  #centent_main{
       width: 75%;
  }
  #right_main{
    //  border: 1px solid red;
     position: absolute;
     right: 5px;
  }

  ul{
    list-style:none;
    font-size: 0.9rem;
    margin: 0 auto;
    a{
      padding: 0.5rem 1rem;
    }
    li{
      display: inline-block;
    }
  }
}


.navbar_dark {
  float: left;
  padding: 2px 10px;
  color: #eb8603;
  font-size: 1.25rem;
  margin-right: 1rem;
  line-height: inherit;
  white-space: nowrap;
}

#nav_header {
  position: relative;
  line-height: 30px;
  text-align: center;
  color: #333;
  box-sizing: border-box;
}

.el-header,
.el-footer {
  padding: 0 0px;
  background-color: #fff !important;
  min-height: 24px;
  max-height: 60px;
  overflow: hidden;
  box-shadow: 0 3px 3px #e4e2e2;
  margin-bottom: 5px;
  padding: 0.6rem 1rem;
}
.el-main{
  background-color: #eff0f4;
}
</style>
  