import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import router from './router';

// vxe-table 移除固定样式,更改scss全局样式 import 'vxe-table/lib/style.css' 
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/styles/variable.scss';
import 'vxe-table/styles/modules.scss';


VXETable.setup({
  size: "mini", // 全局尺寸
  zIndex: 999, // 全局 zIndex 起始值，如果项目的的 z-index 样式值过大时就需要跟随设置更大，避免被遮挡
  version: 0, // 版本号，对于某些带数据缓存的功能有用到，上升版本号可以用于重置数据
  table: {
    resizable: false,
    autoResize: false,
    stripe: true,
    border: false,
    align: "center",
    emptyText: '暂无数据',
  },
  pager: {
    size: 'mini',
    align: "left",
    border:true,
    autoHidden: false,
    perfect: true,
    pageSize: 20,
    pagerCount: 10,
    pageSizes: [20],
    autoHidden:true,
    layouts: ['PrevPage', 'JumpNumber', 'NextPage', 'FullJump','Total']
  },


})
// 引用组件  设置全局样式small 
Vue.use(ElementUI,{ size: 'small',zIndex: 3000 });
Vue.use(VXETable)


//去除报错 提示
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
