import Vue from 'vue'
import Router from 'vue-router'
import Layout from "@/layout"
Vue.use(Router)

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * roles: ['admin', 'common']       // 访问路由的角色权限
 * permissions: ['a:a:a', 'b:b:b']  // 访问路由的菜单权限
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */
//使用记录
const record = [
    { path: "/record/deliveryRecord",name: "deliveryRecord",lable: "投递记录"},  
    { path: "/record/senderMail",name: "senderMail",lable: "寄件记录"},  
    { path: "/record/openBox",name: "openBox",lable: "开箱记录"},  
    { path: "/record/smsState",name: "smsState",lable: "短信状态"},  
    { path: "/record/remarkeManage",name: "remarkeManage",lable: "备注管理"},  
    { path: "/record/exceptionManage",name: "exceptionManage",lable: "故障管理"},  
 ];

 //箱柜设置
 const cabinet = [
    { path: "/cabinet/cabinetManage",name: "cabinetManage",lable: "柜子管理"},  
    { path: "/cabinet/mailRates",name: "mailRates",lable: "快递收费模式"},  
    { path: "/cabinet/temporaryRates",name: "temporaryRates",lable: "暂存收费模式"},  
    { path: "/cabinet/courierManage",name: "courierManage",lable: "快递员管理"}, 
    { path: "/cabinet/advertisingManage",name: "advertisingManage",lable: "广告管理"}, 
    { path: "/cabinet/userWhiteList",name: "userWhiteList",lable: "入柜白名单"}, 
 ];

 //财务信息
 const  finance =[
    { path: "/finance/accountBalance",name: "accountBalance",lable: "账户余额"},
    { path: "/finance/serviceAnnual",name: "serviceAnnual",lable: "服务年费"},
    { path: "/finance/bankCard",name: "bankCard",lable: "银行卡管理"},
  ]
//汇总统计
const dataStatistics = [
    { path: "/dataStatistics/dataStatistics",name: "dataStatistics",lable: "收入统计"},
    { path: "/dataStatistics/orderAll",name: "orderAll",lable: "单量统计"},
  ]

//系统设置
 const systemSetting = [
    { path: "/systemSetting/systemSetting",name: "systemSetting",lable: "系统设置"},
    { path: "/systemSetting/accountManage",name: "accountManage",lable: "账号管理"},
  ]


// 公共路由
export const constantRoutes = [
    //   {
    //     path: '/login',
    //     component: () => import('@/views/login'),
    //   },
    {
        path: '',
        component: Layout,
        redirect: "/index",
        children: [
            //首页
            {
                path: '/index',
                component: () => import('@/views/index'),
                meta:[]
            },
            //#region  使用记录 ----start----- 
            {
                path: '/record',
                redirect: "/record/deliveryRecord",
                meta: record
            }, 
            // 投递记录
            {
                path: '/record/deliveryRecord',
                component: () => import('@/views/record/deliveryRecord/index'),
                meta: record
            }, 
            // 寄件记录
            {
                path: '/record/senderMail',
                component: () => import('@/views/record/senderMail/index'),
                meta: record
            }, 
            // 开箱记录
            {
                path: '/record/openBox',
                component: () => import('@/views/record/openBox/index'),
                meta: record
            },  
            // 短信状态
            {
                path: '/record/smsState',
                component: () => import('@/views/record/smsState/index'),
                meta: record
            },  
            // 备注管理
            {
                path: '/record/remarkeManage',
                component: () => import('@/views/record/remarkeManage/index'),
                meta: record
            },  
            // 故障管理
            {
                path: '/record/exceptionManage',
                component: () => import('@/views/record/exceptionManage/index'),
                meta: record
            },
            //#endregion --------------------使用记录----end--------------
            
            //#region --------------------箱柜设置----start--------------
            {
                path: '/cabinet',
                redirect: "/cabinet/cabinetManage",
                meta: cabinet
            }, 
            // 柜子管理
            {
                path: '/cabinet/cabinetManage',
                component: () => import('@/views/cabinet/cabinetManage/index'),
                meta: cabinet
            }, 
           // 快递收费模式
            {
                path: '/cabinet/mailRates',
                component: () => import('@/views/cabinet/mailRates/index'),
                meta: cabinet
            }, 
             // 暂存收费模式
             {
                path: '/cabinet/temporaryRates',
                component: () => import('@/views/cabinet/temporaryRates/index'),
                meta: cabinet
            }, 
             // 快递员管理
             {
                path: '/cabinet/courierManage',
                component: () => import('@/views/cabinet/courierManage/index'),
                meta: cabinet
            }, 
             // 广告管理
             {
                path: '/cabinet/advertisingManage',
                component: () => import('@/views/cabinet/advertisingManage/index'),
                meta: cabinet
            }, 
              // 入柜白名单
              {
                path: '/cabinet/userWhiteList',
                component: () => import('@/views/cabinet/userWhiteList/index'),
                meta: cabinet
            }, 
            //#endregion --------------------箱柜设置----end--------------

            //#region --------------------财务信息----start--------------
            {
                path: '/finance',
                redirect: "/finance/accountBalance",
                meta: finance
            }, 
            // 账户余额
            {
                path: '/finance/accountBalance',
                component: () => import('@/views/finance/accountBalance/index'),
                meta: finance
            }, 
            // 服务年费
            {
                path: '/finance/serviceAnnual',
                component: () => import('@/views/finance/serviceAnnual/index'),
                meta: finance
            }, 
             // 银行卡管理
             {
                path: '/finance/bankCard',
                component: () => import('@/views/finance/bankCard/index'),
                meta: finance
            }, 
            //#endregion --------------------财务信息----end--------------
         
            //#region --------------------汇总统计----start--------------
            {
                path: '/dataStatistics',
                redirect: "/dataStatistics/dataStatistics",
                meta: dataStatistics
            },
            // 收入统计
            {
                path: '/dataStatistics/dataStatistics',
                component: () => import('@/views/dataStatistics/incomeAll/index'),
                meta: dataStatistics
            }, 
             // 单量统计
             {
                path: '/dataStatistics/orderAll',
                component: () => import('@/views/dataStatistics/orderAll/index'),
                meta: dataStatistics
            }, 
            //#endregion --------------------汇总统计----end--------------

          //#region --------------------系统设置----start--------------
          {
                path: '/systemSetting',
                redirect: "/systemSetting/systemSetting",
                meta: systemSetting
            }, 
            // 系统设置
            {
                path: '/systemSetting/systemSetting',
                component: () => import('@/views/systemSetting/systemSetting/index'),
                meta: systemSetting
            }, 
             // 账号管理
             {
                path: '/systemSetting/accountManage',
                component: () => import('@/views/systemSetting/accountManage/index'),
                meta: systemSetting
            }, 
            //#endregion --------------------系统设置----end--------------

        ]
    },
    
]

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return routerPush.call(this,location).catch(err => err)
}

export default new Router({
    mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})